<template>
  <v-container class="pt-0">
    <dx-data-grid
      ref="refMrp"
      :data-source="mrpDataSource"
      :show-borders="true"
      :show-row-lines="true"
      :show-column-lines="true"
      :hover-state-enabled="true"
      :allow-column-resizing="false"
      column-resizing-mode="widget"
      :allow-column-reordering="true"
      :height="gridHeight"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :auto-navigate-to-focused-row="false"
      key-expr="품번"
      row-template="dataRowTemplate"
      @toolbar-preparing="onToolbarPreparingMrp"
    >
      <DxSearchPanel
        :visible="false"
        placeholder="검색..."
      />
      <DxFilterRow
        :visible="false"
        apply-filter="auto"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        :allow-editing="false"
        :fixed="false"
        sort-order="asc"
        caption="품번"
        data-field="품번"
        width="150"
      />
      <DxColumn
        :allow-editing="true"
        caption="품목유형"
        data-field="품목유형"
        :visible="false"
        width="80"
      >
        <DxLookup
          :data-source="productypeLookup"
          display-expr="코드명"
          value-expr="코드"
        />
      </DxColumn>
      <DxColumn
        :allow-editing="false"
        :fixed="false"
        format="#,##0"
        caption="현재공"
        data-field="현재공"
        width="70"
      />
      <DxColumn
        :caption="mrpTitle"
        alignment="center"
      >
        <DxColumn
          v-for="(item, index) in dateArrayMrp"
          :key="index+100"
          :caption="item.caption"
          :data-field="item.dataField"
          :data-type="item.dataType"
          :format="item.format"
          :allow-sorting="false"
        />
      </DxColumn>
      <dx-paging :enabled="false" />
      <dx-scrolling
        mode="virtual"
      />
      <dx-sorting mode="multiple" />
      <template #dataRowTemplate="{ data: rowInfo }">
        <tbody
          :class="{'dx-row-toggle': rowInfo.rowIndex % 2}"
          class="week-plan "
        >
          <tr class="main-row">
            <td
              rowspan="2"
              style="overflow:hidden;"
            >
              {{ rowInfo.data.품번 }}
            </td>
            <td
              rowspan="2"
              class="row-number"
              style="color:green;"
            >
              {{ rowInfo.data.현재공 }}
            </td>
            <td
              v-for="(item, index) in dateArrayMrp"
              :key="index+200"
              class="row-number"
            >
              {{ rowInfo.data[item.dataField] }}
            </td>
          </tr>
          <tr class="stock-row">
            <td
              v-for="(item, index) in dateArrayMrp"
              :key="index+300"
              class="row-number"
              :class="{'dx-row-minus': rowInfo.data[item.stockField] < 0}"
            >
              {{ rowInfo.data[item.stockField] }}
            </td>
          </tr>
        </tbody>
      </template>
    </dx-data-grid>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, {
  DxScrolling, DxFilterRow, DxColumnFixing,
  DxColumn, DxPaging, DxSorting, DxSearchPanel, DxLookup
} from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import axios from 'axios'

export default {
  name: 'MainPage',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxColumnFixing,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxLookup
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      gridHeight: null,
      mrpDataSource: null,
      today: null,
      productypeLookup: null,
      dateArrayMrp: [],
      mrpTitle: null,
      thisSundayDate: null,
      thisWeekendDate: null,
      nextWeekendDate: null
    }
  },
  computed: {
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.today = AppLib.getToday()
    this.productypeLookup = this.$store.getters.getBaseDataByType(ConstDef.품목유형)
    // console.log(this.productypeLookup)
    this.calcNextWeekendDay(this.today)
    this.refreshWeekPlan()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 90) - 20
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 90) - 20
    },
    calcNextWeekendDay (day) {
      const week = AppLib.getWeekFromDateFormat(day)
      this.thisSundayDate = AppLib.calcDateFromToday(week * -1)
      this.weekDays = 2
      this.thisWeekendDate = AppLib.calcDateFromToday(6 - week)
      this.nextWeekendDate = AppLib.calcDateFromToday((6 - week) + 7)
    },
    getText (code) {
      return this.$_.findWhere(this.productypeLookup, { 코드: code }).코드명
    },
    makeWeekModel (planInfo, product) { // planInfo ={생산일자 품번 품명 수량}
      this.planInfoList = []
      if (planInfo === null) {
        for (let i = 0; i < product.length; i++) {
          const temp = {
            품번: product[i].품번,
            품명: product[i].품명
          }
          for (let j = 0; j < this.weekDays; j++) {
            temp.생산일자 = AppLib.calcDateFromFormat(this.today, j)
            const key = `${temp.생산일자.substring(5, 7)}/${temp.생산일자.substring(8, 10)}`
            temp[key] = null
          }
          this.planInfoList.push(temp)
        }
        return
      }
      for (let i = 0; i < product.length; i++) {
        const temp = {
          품번: product[i].품번,
          품명: product[i].품명
        }
        for (let j = 0; j < this.weekDays; j++) {
          temp.생산일자 = AppLib.calcDateFromFormat(this.today, j)
          const timeStamp = AppLib.getTimestampFromString(`${temp.생산일자} 00:00:00`)
          const key = `${temp.생산일자.substring(5, 7)}/${temp.생산일자.substring(8, 10)}`
          let where = this.$_.findWhere(planInfo, { 생산일자: timeStamp, 품번: product[i].품번 })
          if (where === undefined) {
            where = this.$_.findWhere(planInfo, { 생산일자: temp.생산일자, 품번: product[i].품번 })
          }
          if (where === undefined) {
            temp[key] = null
          } else {
            temp[key] = where.수량
          }
        }
        this.planInfoList.push(temp)
      }
    },
    onToolbarPreparingMrp (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return "<div class='toolbar-label' style='margin-left:4px;color:cyan;'><b>주간  자재 소요량</b></div>"
          }
        }
      )
    },
    refreshMrp () {
      this.isLoading = true
      this.mrpTitle = 'MRP(소요/부족)'
      const param = {
        title: ConstDef.WEEK_PLAN_KEY,
        fromDate: AppLib.getToday(),
        toDate: AppLib.calcDateFromToday(1),
        품목유형: ConstDef.다공정품,
        bomOption: 'ALL_NODE'
      }
      const distinctProc = this.$_sp.runNoEncodeFindProc('spFindAllProductByFirstWipUnionRaw', param)
      const distinctProcAll = this.$_sp.runNoEncodeFindProc('spFindAllMrpDisPCodeByTitleAndDateAndNotSemi', param)
      const mrpProc = this.$_sp.runNoEncodeFindProc('spFindAllWeekMrpByTitleAndDateFromText', param)
      axios.all([distinctProc, distinctProcAll, mrpProc])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          const goods = this.$_sp.MakeModel(reponse[0])
          const goodsAll = this.$_sp.MakeModel(reponse[1])
          const mrp = this.$_sp.MakeModel(reponse[2])
          console.log(mrp)
          this.setInitDataSource(goods, goodsAll, mrp)
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshWeekPlan () {
      const param = {
        sunday: this.thisSundayDate,
        fromDate: this.today,
        toDate: this.nextWeekendDate,
        goodsType: ConstDef.다공정품
      }
      const weekPLan = this.$_sp.runFindProc('spFindAllWeekPlan', param)
      const product = this.$_sp.runFindProc('spFindAllProductByFirstWip', {})
      this.isLoading = true
      axios.all([weekPLan, product])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          const thisWeek = this.$_sp.MakeModel(reponse[0])
          const productInfo = this.$_sp.MakeModel(reponse[1])
          // console.log(thisWeek)
          // console.log(productInfo)
          this.makeWeekModel(thisWeek, productInfo)

          this.refreshMrp()
        }))
        /*
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        }) */
    },
    setInitDataSource (wipGoods, goodsAll, mrp) {
      this.dateArrayMrp = []
      let i = 0
      let day = null
      for (i = 0; i < this.weekDays; i++) {
        day = AppLib.calcDateFromFormat(this.today, i)
        const temp = {
          caption: `${day.substring(5, 7)}/${day.substring(8, 10)}`,
          dataField: `${day.substring(5, 7)}/${day.substring(8, 10)}소요`,
          format: '#,##0',
          dataType: 'number',
          stockField: `${day.substring(5, 7)}/${day.substring(8, 10)}부족`
        }
        this.dateArrayMrp.push(temp)
      }
      this.mrpInfoListGroup = []
      for (i = 0; i < goodsAll.length; i++) {
        let where = this.$_.where(mrp, { 품번: goodsAll[i].품번 })
        where = this.$_.sortBy(where, '소요일자')
        let sum = 0
        for (let j = 0; j < where.length; j++) {
          if (j === 0) {
            goodsAll[i].현재고 = where[j].현재고
            goodsAll[i].현재공 = where[j].현재공
          }
          day = AppLib.formatYMD(where[j].소요일자)
          day = `${day.substring(5, 7)}/${day.substring(8, 10)}`
          const stock = `${day}부족`
          goodsAll[i][`${day}소요`] = where[j].소요량
          sum += where[j].소요량
          goodsAll[i][stock] = where[j].현재고 + where[j].현재공 - sum
        }
        goodsAll[i].합계 = sum
      }
      // 다공정품 그룹핑
      for (let j = 0; j < goodsAll.length; j++) {
        if (goodsAll[j].품목유형 !== ConstDef.다공정품) {
          this.mrpInfoListGroup.push(this.$Q.extend(true, {}, goodsAll[j]))
        }
      }
      let tempItem = {}
      let j = 0
      let k = 0
      for (j = 0; j < goodsAll.length; j++) {
        if (goodsAll[j].품목유형 !== ConstDef.다공정품) {
          continue
        }
        tempItem = this.$Q.extend(true, {}, goodsAll[j])
        let inventory = tempItem.현재고 + tempItem.현재공

        for (k = j; k < goodsAll.length; k++) {
          if (goodsAll[k + 1] === undefined || goodsAll[k + 1].품번.indexOf(goodsAll[j].품번) < 0) {
            j = k
            break
          }
          if (goodsAll[k + 1].자재유형 === ConstDef.자재유형_원자재) {
            continue
          }

          tempItem.현재고 += goodsAll[k + 1].현재고
          tempItem.현재공 += goodsAll[k + 1].현재공
          if (goodsAll[k + 2] === undefined || goodsAll[k + 2].품번.indexOf(goodsAll[j].품번) < 0) {
            inventory -= (tempItem.현재고 + tempItem.현재공)
            for (const key in tempItem) {
              if (key.indexOf('부족') >= 0) {
                tempItem[key] -= inventory
              }
            }
          }
        }
        this.mrpInfoListGroup.push(tempItem)
      }
      // console.log(this.mrpInfoListGroup)
      this.mrpDataSourceOrg = []
      for (k = 0; k < wipGoods.length; k++) {
        // console.log(k)
        for (j = 0; j < this.mrpInfoListGroup.length; j++) {
          if (wipGoods[k].품번 === this.mrpInfoListGroup[j].품번) {
            this.mrpDataSourceOrg.push(this.mrpInfoListGroup[j])
          }
        }
      }

      for (k = 0; k < wipGoods.length; k++) {
        // console.log(k)
        for (j = 0; j < this.mrpInfoListGroup.length; j++) {
          if (wipGoods[k].품목유형 === ConstDef.다공정품 && wipGoods[k].품번.indexOf(this.mrpInfoListGroup[j].품번) >= 0) {
            // this.mrpInfoListGroup[j].품번 = wipGoods[k].품번
            this.mrpDataSourceOrg.push(this.mrpInfoListGroup[j])
          }
        }
      }
      // console.log(this.mrpDataSourceOrg)

      this.reCalcMrp(this.$Q.extend(true, [], this.mrpDataSourceOrg))
    },
    reCalcMrp (dataSource) {
      let k = 0; let i = 0; let j = 0; let m = 0; let n = 0; let day = null; let key = null; let qty = 0
      let day1 = null; let key1 = null; let before = 0

      // console.log(this.planInfoList)
      for (k = 0; k < dataSource.length; k++) {
        for (j = 0; j < this.planInfoList.length; j++) {
          if (this.planInfoList[j].품번.indexOf(dataSource[k].품번) >= 0) {
            const items = this.$_.filter(dataSource, function (item) {
              if (item.품번.indexOf(dataSource[k].품번) >= 0) {
                return item
              }
            })
            // console.log(items)
            for (m = 0; m < items.length; m++) {
              if (items[m].품번 === dataSource[k].품번) { // 가공품번
                for (i = 1; i < this.weekDays; i++) {
                  day = AppLib.calcDateFromFormat(this.today, i - 1)
                  key = `${day.substring(5, 7)}/${day.substring(8, 10)}`
                  qty = this.planInfoList[j][key] === null ? 0 : this.planInfoList[j][key]
                  if (qty !== null) {
                    for (n = i; n < this.weekDays; n++) {
                      day1 = AppLib.calcDateFromFormat(this.today, n)
                      key1 = `${day1.substring(5, 7)}/${day1.substring(8, 10)}`
                      if (items[m][`${key1}부족`] !== undefined) {
                        before = items[m][`${key1}부족`] += qty
                      } else {
                        items[m][`${key1}부족`] = before
                      }
                    }
                  }
                }
              } else { // 소재 품번
                for (i = 0; i < this.weekDays; i++) {
                  day = AppLib.calcDateFromFormat(this.today, i)
                  key = `${day.substring(5, 7)}/${day.substring(8, 10)}`
                  items[m][`${key}소요`] = this.planInfoList[j][key]
                }
                before = items[m].현재고 + items[m].현재공
                for (i = 0; i < this.weekDays; i++) {
                  day = AppLib.calcDateFromFormat(this.today, i)
                  key = `${day.substring(5, 7)}/${day.substring(8, 10)}`
                  before = items[m][`${key}부족`] = before - items[m][`${key}소요`]
                }
              }
            }
          }
        }
      }
      this.mrpDataSource = this.$_.where(dataSource, { 품목유형: ConstDef.구매품 })
    }
  }
}
</script>

<style lang="scss">
.week-plan {
  tr{
    border-bottom: 1px solid rgb(204, 204, 204);
    height: 27px;
  }
  td{
    border-right: 1px solid rgb(221,221,221);
    padding: 0 6px;
  }
  .row-number-span {
    text-align: right;
  }
  .row-number {
    text-align: right;
    border-left: 0px solid rgb(204, 204, 204);
  }
  .dx-row-minus {
    color: red;
  }

}
</style>
